import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import React, { ReactNode, useMemo } from 'react'

import ReloadAlert from 'components/shared/BaseApp/ReloadAlert'
import AppConfigContext from 'contexts/AppConfigContext'
import useMakeTheme from 'hooks/useMakeTheme'
import getAppConfig from 'utils/getAppConfig'

const BaseApp = ({ children }: { children?: ReactNode }) => {
  const appConfig = useMemo(() => getAppConfig(), [])
  const theme = useMakeTheme(appConfig)

  return (
    <AppConfigContext.Provider value={appConfig}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <ReloadAlert />
          {children}
        </ThemeProvider>
      </LocalizationProvider>
    </AppConfigContext.Provider>
  )
}

export default BaseApp
