import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import React, { useEffect } from 'react'

import { addErrorHook, removeErrorHook } from 'apis/apiService'

const HOOK_KEY = 'reloadAlerter'

const ReloadAlert = () => {
  const [open, setOpen] = React.useState<boolean>(false)

  useEffect(() => {
    addErrorHook(HOOK_KEY, (error) => {
      const errorType = error.response?.data?.errorType
      const status = error.response?.status
      if (status === 400 && errorType === 'invalid_authenticity_token') {
        setOpen(true)
      }
    })

    return () => {
      removeErrorHook(HOOK_KEY)
    }
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const reload = () => {
    window.location.reload()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="reload-alert-dialog-title"
      aria-describedby="reload-alert-dialog-description"
    >
      <DialogTitle id="reload-alert-dialog-title">Error</DialogTitle>
      <DialogContent>
        <DialogContentText id="reload-alert-dialog-description">
          The page appears to be stale. Please reload the page and try again. The data you have
          inputted on the page will be lost after reloading. If you need it, click cancel to make
          note of what was entered and then reload the page manually.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={reload} autoFocus>
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReloadAlert
